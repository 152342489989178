@keyframes firework {
  0% {
    transform: translate(-50%, 55vh);
    width: 0.55vmin;
    opacity: 1;
  }
  50% {
    width: 0.55vmin;
    opacity: 1;
  }
  100% {
    width: 55vmin;
    opacity: 0;
  }
}

.firework,
.firework::before,
.firework::after {
  --top: 65vh;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -55%);
  width: 0.55vmin;
  aspect-ratio: 1;
  background: radial-gradient(circle, #ffee73 0.2vmin, #0000 0) 50% 00%,
    radial-gradient(circle, #ef6f6c 0.3vmin, #0000 0) 00% 50%,
    radial-gradient(circle, #ffad75 0.5vmin, #0000 0) 50% 99%,
    radial-gradient(circle, #56e39f 0.2vmin, #0000 0) 99% 50%,
    radial-gradient(circle, #586cbf 0.3vmin, #0000 0) 80% 90%,
    radial-gradient(circle, #ffee73 0.5vmin, #0000 0) 95% 90%,
    radial-gradient(circle, #ef6f6c 0.5vmin, #0000 0) 10% 60%,
    radial-gradient(circle, #ffad75 0.2vmin, #0000 0) 31% 80%,
    radial-gradient(circle, #56e39f 0.3vmin, #0000 0) 80% 10%,
    radial-gradient(circle, #586cbf 0.2vmin, #0000 0) 90% 23%,
    radial-gradient(circle, #ffee73 0.3vmin, #0000 0) 45% 20%,
    radial-gradient(circle, #ffffff 0.5vmin, #0000 0) 13% 24%;
  background-size: 0.55vmin 0.55vmin;
  background-repeat: no-repeat;
  animation: firework 2.5s infinite;
}

.firework::before {
  transform: translate(-50%, -50%) rotate(25deg) !important;
}

.firework::after {
  transform: translate(-50%, -50%) rotate(-40deg) !important;
}

.img-wiggle {
  animation: img-wiggle 3s infinite;
}

@keyframes img-wiggle {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  85% {
    transform: rotate(6deg);
  }
  90% {
    transform: rotate(-6deg);
  }
  95% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
