@font-face {
  font-family: "Montserrat" !important;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url(https://fonts.gstatic.com/s/montserrat/v13/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html {
  overflow: hidden !important;
}

body {
  overflow: hidden !important;
}
#root {
  height: 100%;
}

#mapId {
  height: 300px;
  width: 100%;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

/* Change Autocomplete hover suggestion font size styles in Chrome */
input:-webkit-autofill::first-line {
  font-size: 15px;
}

html {
  height: 100vh !important;
  max-height: 100vh !important;
  overflow: hidden !important;
}

button {
  outline: none !important;
  font-family: "Montserrat" !important;
  font-weight: bold !important;
  font-size: 12.75px !important;
}

input {
  outline: none !important;
  font-family: "Montserrat" !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh !important;
  max-height: 100vh !important;
}

a:hover {
  text-decoration: none;
}

.breadcrumb-item a {
  color: #dcdcdd;
}

.breadcrumb-item a:hover {
  text-decoration: none;
  color: #fff;
}

.breadcrumb span {
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 600 !important;
}

.breadcrumb span.active {
  font-weight: bolder;
  color: #fff;
}

.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker-wrapper {
  display: block !important;
  width: 100%;
}

.btn-outline-primary {
  color: #0b2545;
  border-color: #0b2545;
}
.btn-outline-primary:hover {
  color: #ffffff;
  border-color: #0b2545;
  background: #0b2545;
  opacity: 0.9;
}
.btn-primary {
  color: #ffffff;
  border-color: #0b2545;
  background: #0b2545;
}
.btn-primary:hover {
  color: #ffffff;
  border-color: #0b2545;
  background: #0b2545;
  opacity: 0.9;
}
.btn-outline-danger {
  color: #ef6f6c;
  border-color: #ef6f6c;
}
.btn-outline-danger:hover {
  color: #ffffff;
  border-color: #ef6f6c;
  background: #ef6f6c;
  opacity: 0.9;
}
