#demo-editor .ql-toolbar.ql-snow {
  background-color: #f5f5f5;
  border: 0;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #ccc;
}
#demo-editor .ql-container {
  border: 0;
  font-family: "Montserrat";

  overflow: auto;
}

.ql-editor.ql-blank:focus::before {
  content: "";
}

.ql-editor h1 {
  font-size: 1.9rem;
  padding-bottom: 1rem !important;
}

.ql-editor h2 {
  font-size: 1.5rem;
  padding-bottom: 1rem !important;
}

.ql-editor h3 {
  font-size: 1.1rem;
  padding-bottom: 1rem !important;
}

.ql-editor h4 {
  font-size: 1rem;
  font-style: italic;
  padding-bottom: 1rem !important;
}

.ql-editor p {
  font-size: 1rem;
  padding-bottom: 0.75rem !important;
}

.ql-editor a {
  color: #2200cc !important;
}

.ql-editor a:hover {
  opacity: 0.8;
  cursor: pointer;
}

.ql-snow .ql-picker.ql-header {
  font-weight: 700;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
  content: "Headline" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
  content: "Preamble" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
  content: "Dateline" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before {
  content: "Image text" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item:before,
.ql-snow .ql-picker.ql-header .ql-picker-label:before {
  content: "Paragraph" !important;
}
