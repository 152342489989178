.app-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .app-container {
    overflow: auto;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #aaa;
}

::-webkit-scrollbar-thumb:hover {
  opacity: 0.75;
  cursor: pointer;
}

.left-col {
  max-height: 100%;
  overflow: hidden;
}

.right-col {
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}

@media screen and (max-width: 900px) {
  .left-col {
    display: none;
  }
  .right-col {
    overflow: hidden;
  }
  #burger-menu {
    display: inline-block !important;
  }
}

textarea {
  outline: none;
}

.pointer {
  cursor: pointer !important;
}

.dropdown-transition {
  transform: scaleX(1) !important;
  transition: all 0.1s ease-in-out !important;
  transform-origin: top !important;
}

.breadcrumb {
  margin: 0;
  color: #e8eef2;
  background: none;
  font-size: 0.9em;
}

.recentSearch {
  padding: 8px;
  padding-top: 14px;
  padding-left: 24px;
  display: block;
}

.imatrics-default-button {
  color: #586cbf !important;
  border: 1px solid #586cbf !important;
}

.done-button {
  color: #586cbf !important;
  border: 1px solid #586cbf !important;
}

.error-button {
  color: #586cbf !important;
  border: 1px solid #586cbf !important;
}

.imatrics-blue-button {
  color: #586cbf !important;
  border: 1px solid #586cbf !important;
}

.btn-danger {
  background: #ef6f6c;
}

#burger-menu {
  display: none;
}

.dropdown-toggle:after {
  display: none;
}

.burger-icon {
  color: #e8eef2;
  display: inline;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.burger-icon:hover {
  transform: scale(1.2);
  color: #ffffff;
}

.burger-item {
  cursor: pointer;
  background-color: #4c5c68;
  height: fit-content;
  z-index: 11;
}

.burger-item:hover {
  cursor: pointer;
  opacity: 0.8;
  background-color: #4c5c68;
  height: fit-content;
}

.burger-link {
  color: #e8eef2 !important;
  font-weight: bold !important;
}

.show {
  padding: 0;
  margin: 0;
  border: none;
}

#user {
  text-align: center;
  color: #0b2545;
  background-color: #e8eef2;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.5em 0;
  margin-right: 0.5em;
  border: 1px solid #0b2545;
  transform: scale(0.8);
}

#user:hover {
  opacity: 0.9;
}

#user-id {
  color: #dcdcdd;
  background-color: #4c5c68;
  font-size: 0.9em;
  width: 100%;
  border-bottom: 0.8px solid #0b2545;
  text-align: center;
  padding: 0.3em;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.user-item {
  font-size: 0.9em;
  color: #e8eef2;
  background-color: #4c5c68;
  cursor: pointer;
  font-weight: bold;
  height: fit-content;
  border: none;
  transition: all 0.2s ease-in-out;
  border-bottom: 0.8px solid #0b2545;
  padding: 5px;
}
.user-item:hover {
  color: #fff;
  background-color: #4c5c68;
}

.menu-dropdown-icon {
  margin-right: 2vw;
  margin-top: 7px;
  margin-bottom: 7px;
}

.user-dropdown-icon {
  margin: 0 0.5em !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

#subtypes-input {
  margin-right: 60px;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.breadcrumb-item.active {
  color: #e8eef2 !important;
  font-weight: 700 !important;
}
