.interactive-cell {
  cursor: pointer;
}

.interactive-cell:hover {
  opacity: 0.9;
}

.title-icon {
  margin-right: 8px;
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 0 !important;
    padding-right: 24px;
  }
}

/*
.MuiTablePagination-toolbar {
  min-height: 52px;
  padding-right: 2px;
}
*/
