.mde-preview-content {
  font-family: "Roboto", sans-serif !important;
}

.mde-preview .mde-preview-content p {
  font-size: 1rem !important;
}

.mde-preview .mde-preview-content h1 {
  border-bottom: none !important;
  font-weight: 700 !important;
}

.mde-preview .mde-preview-content h2 {
  border-bottom: none !important;
  font-weight: 700 !important;
}

.mde-preview .mde-preview-content h3 {
  border-bottom: none !important;
  font-size: 20px !important;
}

.mde-preview .mde-preview-content h4 {
  border-bottom: none !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.mde-preview .mde-preview-content h5 {
  border-bottom: none !important;
}

.mde-preview .mde-preview-content h6 {
  border-bottom: none !important;
}

.mde-preview .mde-preview-content .badge {
  padding: 6px !important;
  margin-right: 1ch !important;
  font-size: 15px !important;
}

.mde-preview .mde-preview-content .card {
}

.auto-scroll {
  overflow: hidden;
  overflow-x: auto;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600");

.active-section {
  border-left: 4px solid #0b2545 !important;
}
