.tag-badge {
  display: inline-flex;
  color: #0b2545;
  background-color: #e8eef2;
  padding: 1%;
  margin: 1.2%;
  box-shadow: 2px 2px 6px -4px rgba(77, 92, 105);
}

.popper-wrapper {
  position: relative !important;
  width: 100% !important;
  z-index: 9;
}

.popper-wrapper div {
  width: 100% !important;
  margin-bottom: 16px;
}

.popover-index {
  max-height: 200px;
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}

.article-table-row {
  cursor: pointer;
}



.interactive {
  cursor: pointer;
}

.interactive:hover {
  opacity: 0.8;
}

.MuiMenuItem-root {
  font-family: inherit !important;
}

.MuiInputLabel-outlined {
  color: #000 !important;
  font-weight: bold !important;
}
