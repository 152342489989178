.sidebar {
  z-index: 2;
  color: white;
  background-color: #0b2545; /*Oxford blue*/
  height: 100%;
  max-height: 100%;
  font-size: 13px;
}

.activeSideBarLink {
  background: #214263;
  color: #e8eef2;
}

.grow-logo {
  transition: all 0.2s ease-in-out;
}
.grow-logo:hover {
  transform: scale(1.05);
}

.sidebar-list-item {
  color: #fff;
  font-weight: 700;
}

.sidebar-list-item:hover {
  color: #fff;
  background-color: #214263 !important;
}

.activeSidebarListItem {
  background-color: #214263 !important;
}

.activeSidebarListItemSub {
  color: #fff;
  background-color: rgba(33, 66, 99, 0.7) !important;
}

.sidebar-icon {
  color: inherit !important;
  min-width: unset !important;
}
